var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mt-12", attrs: { justify: "center" } },
    [
      _c(
        "v-slide-y-transition",
        { attrs: { appear: "" } },
        [
          _c(
            "BaseMaterialCard",
            {
              staticClass: "px-5 py-3",
              attrs: {
                color: "accent",
                light: "",
                "max-width": "100%",
                width: "400"
              },
              scopedSlots: _vm._u([
                {
                  key: "heading",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("v-img", {
                            staticClass: "ma-2",
                            attrs: { src: require("@/assets/logo_white.png") }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-form",
                    [
                      _vm.error
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "red--text font-weight-bold text-left"
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { color: "red" }
                                },
                                [
                                  _vm._v(
                                    "\n              mdi-alert-circle-outline\n            "
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.error) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-text-field", {
                        attrs: {
                          color: "secondary",
                          "prepend-icon": "mdi-email-outline",
                          "error-messages": _vm.emailErrors,
                          required: "",
                          name: "email",
                          label: "Email",
                          type: "email"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.email.$touch()
                          },
                          blur: function($event) {
                            return _vm.$v.email.$touch()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit()
                          }
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          color: "secondary",
                          "prepend-icon": "mdi-lock-outline",
                          "error-messages": _vm.passwordErrors,
                          required: "",
                          name: "password",
                          label: "Password",
                          type: "password"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.password.$touch()
                          },
                          blur: function($event) {
                            return _vm.$v.password.$touch()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit()
                          }
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { justify: "start" } },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "ForgotPassword" } } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-7",
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    tag: "button"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Forgot Password?\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "pages-btn",
                            {
                              staticClass: "v-btn--text success--text mt-4",
                              attrs: {
                                large: "",
                                color: "secondary",
                                depressed: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submit()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  "\n                mdi-login\n              "
                                )
                              ]),
                              _vm._v("Log in\n            ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }