<template>
  <v-row
    justify="center"
    class="mt-12"
  >
    <v-slide-y-transition appear>
      <BaseMaterialCard
        color="accent"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template v-slot:heading>
          <div class="text-center">
            <v-img
              src="@/assets/logo_white.png"
              class="ma-2"
            />
          </div>
        </template>

        <v-card-text class="text-center">
          <v-form>
            <p
              v-if="error"
              class="red--text font-weight-bold text-left"
            >
              <v-icon
                color="red"
                class="mr-1"
              >
                mdi-alert-circle-outline
              </v-icon>
              {{ error }}
            </p>

            <v-text-field
              v-model="email"
              color="secondary"
              prepend-icon="mdi-email-outline"
              :error-messages="emailErrors"
              required
              name="email"
              label="Email"
              type="email"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              @keyup.enter="submit()"
            />

            <v-text-field
              v-model="password"
              color="secondary"
              prepend-icon="mdi-lock-outline"
              :error-messages="passwordErrors"
              required
              name="password"
              label="Password"
              type="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              @keyup.enter="submit()"
            />

            <v-row justify="start">
              <router-link :to="{ name: 'ForgotPassword' }">
                <v-btn
                  text
                  color="primary"
                  class="ml-7"
                  tag="button"
                >
                  Forgot Password?
                </v-btn>
              </router-link>
            </v-row>
            <v-row justify="center">
              <pages-btn
                large
                color="secondary"
                depressed
                class="v-btn--text success--text mt-4"
                @click="submit()"
              >
                <v-icon left>
                  mdi-login
                </v-icon>Log in
              </pages-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </BaseMaterialCard>
    </v-slide-y-transition>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { DefaultUnauthorizedMessage } from '@/shared/axios-config'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'auth'
)

export default {
  name: 'AppLogin',

  components: {
    PagesBtn: () => import('../components/Btn')
  },

  mixins: [validationMixin],

  validations: {
    email: { required, email },
    password: { required }
  },

  data: () => ({
    error: null,
    email: null,
    password: null
  }),

  computed: {
    ...mapGetters(['getEmail']),
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid email')
      !this.$v.email.required && errors.push('Email is required')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required')
      return errors
    }
  },

  created () {
    this.clearCookies()
  },

  mounted () {
    this.email = this.getEmail()
  },

  methods: {
    ...mapActions(['logIn']),
    ...mapMutations(['clearCookies']),
    async submit () {
      this.error = null

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const data = await this.logIn({
        email: this.email,
        password: this.password
      })

      if (data.errorResponse) {
        this.$appInsights.trackEvent({ name: 'Login Failed' }, { mmsUserId: this.email })

        this.error = data.errorResponse === DefaultUnauthorizedMessage ? 'Your email/password is invalid.' : data.errorResponse
        return
      }

      this.$appInsights.trackEvent({ name: 'Login Success' }, { mmsUserId: this.email })

      var telemetryInitializer = (envelope) => {
        envelope.data.mmsUserId = this.email
      }
      this.$appInsights.addTelemetryInitializer(telemetryInitializer)
      if (this.$route.query.redirect === '/login') {
        this.$route.query.redirect = null
      }
      this.$router.push(this.$route.query.redirect || { name: 'CampaignDashboard' })
    }
  }
}
</script>
